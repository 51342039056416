.m-0 {
  margin: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-10 {
  padding: 10px !important;
}

.pl-dataview-20 {
  padding-left: 10px;
}

.pr-dataview-20 {
  padding-right: 10px;
}

.box-s {
  box-shadow: 0px 2px 6px #0000000a;
  border-radius: 10px;
  overflow: hidden;
}

.dataview {
  padding: 0px 10px 10px 10px;
}

.dataview-title {
  font-size: 18px !important;
  color: #3c3b54;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-left: 20px;
  margin-top: 13px;
}

.charts-dataview {
  height: 220px;
  padding: 8px 12px 0px 12px;
}

.bg-white {
  box-shadow: 3px 3px 25px -1px rgba(196, 196, 196, 1);
  background-color: white;
}

.battery-dataview {
  display: flex;
  justify-content: center;
  align-items: center;

}

.green {
  padding: 6px 7px;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;
  background: #229f5b;
}

.red {
  padding: 6px 7px;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;
  background: #c54848;
}

.mb-dataview-20 {
  margin-bottom: 20px;
}

.line-chart {
  margin: 20px 0px 20px 20px;
  padding: 25px;
  height: 460px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

.css-319lph-ValueContainer {
  width: 80px;
}

th.dataview-tr {
  border-width: 0px !important;
}

td.dataview-td {
  border: 1px;
}

td.dataview-tr {
  border-width: 0px;
}

::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(228, 226, 226);
  width: 10px;
  border-radius: 5px;
  border-right: none;
  border-left: none;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

.graph-outline {
  width: 100%;
}

.line-graph {
  width: 100%;
  max-height: 380px;
  min-width: 600px;
}

.temperature h1 {
  font-size: 45px;
  font-weight: 500;
}

.temp-health {
  margin: 20px 20px 0px 20px;
}

.dataview-temperature-card {
  padding: 50px;
  border-radius: 10px;
  text-align: center;
  color: rgb(50, 45, 45);
  height: 100%;
  background-color: white;
}

.battery {
  width: 210px;
}

.battery-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

h4.battery-tittle {
  text-align: center;
  margin-bottom: 0px !important;
  margin-top: 15px;
  font-size: 22px;
}

h4.table-title {
  text-align: center;
  margin: 0px;
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 10px;
}

.table-title-header {
  padding: 13px;

}

.table-container {
  padding: 0px 10px;

}

.circle-valb {
  margin: auto;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

}

@media screen and (min-width: 576px) and (max-width:991px) {

  .rmr-10 {
    margin-right: 10px;
  }

  .rml-10 {
    margin-left: 10px;
  }

  .temp-health {
    margin: 0px 20px 0px 20px;
  }

  .line-chart {
    margin-right: 0px;
  }

  .table-container {
    padding: 0px;
  }

  .pl-dataview-20,
  .pr-dataview-20 {
    padding: 0px 20px 20px 20px;
  }
}

@media screen and (max-width:991px) {
  .line-chart {
    height: 450px;
  }
}



@media screen and (max-width:575px) {
  .dataview {
    padding: 0px;
  }

  .temp-health {
    margin: 0px 20px;
  }

  .mtr-20 {
    margin-top: 20px;

  }

  .table-container {

    padding: 0px;
  }

  .mb-dataview-20 {
    margin-bottom: 0px;
  }

  .line-chart {
    margin-right: 20px;
  }

  .pl-dataview-20 {
    margin-top: 20px;
  }

  .pr-dataview-20 {
    margin-bottom: 20px;
  }

  .pl-dataview-20,
  .pr-dataview-20 {
    padding: 0px 20px 20px 20px;
  }
}

.dataview-select .form-control {
  /* border: none; */
  height: 39px;


}

.apexcharts-toolbar {
  z-index: 0 !important;
}

.apexButton {
  min-width: 90px !important;
  height: 28px !important;
  background-color: #ffffff;
  border-radius: 4px !important;
  color: #3c3b54;
  border: 1px solid #8d8adf;
}

.apexButton:hover {
  background-color: #8d8adf;
  color: #fff;
  border: 1px solid #8d8adf;
}


.apexButton.active {
  /* width: 73px !important; */
  height: 28px !important;
  color: white;
  background-color: #8d8adf;
}

.CsvBtn{
  min-width: 30px !important;
  height: 28px !important;
  background-color: #ffffff;
  border-radius: 4px !important;
  color: #000000;
  font-size: 12px;
  border: 1px solid #8d8adf;
  margin-left: 10px;
  position: relative;
}
.CsvBtn:hover{
  opacity: 0.6;
  transition: 0.3s;
  background-color: #5e59e8;
}
.CsvBtn svg:hover{
  color: white;
}
.hidden-section{
    position: absolute;
    right: 0px;
    display: flex;
    color: white;
    flex-direction: column;
    background: #7f7cdc;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #8d8adf;
    cursor: pointer;
    font-size: 14px;
    z-index: 1;
  
}

.newGraph {
  display: flex;
  justify-content: space-around;
  /* width: 650px !important; */
  flex-wrap: wrap;
}

.apexcharts-menu-item.exportSVG {

  display: none !important;
}

.apexcharts-menu-item.exportPNG {
  display: none !important;
}

.apexcharts-zoom-icon.apexcharts-selected {
  display: none !important;
}
.apexcharts-tooltip {
  color: #8d8adf;

}