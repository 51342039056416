/* user */
.user-dash {
    background-color: white;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 10px;
    margin-top: 30px;
    padding: 8px 12px 0px 12px;

    min-height: 180px;
}

.img-user img {
    width: 35px;
    height: 35px;
    border-radius: 25px;
    margin-bottom: 10px;
}

.user-text {
    font-size: 16px;
    margin-bottom: 9px;
    color: #3c3b54;
    letter-spacing: 0.5px;
    font-weight: 700;
    padding: 8px 12px;
    overflow: hidden;
}

.lastNotificationUserText {
    max-height: 100px;
    font-size: 14px;
    margin-bottom: 9px;
    color: #3c3b54;
    letter-spacing: 0.5px;
    font-weight: 700;
    padding: 8px 12px;
    overflow-y: scroll;

}

.role-name {
    font-size: 14px;
    margin-bottom: 13px;
    letter-spacing: 1px;
    color: #4d4f5c;
    font-weight: 500;
    margin-left: 6px;
}

.role-count {
    font-size: 13px;
    text-align: end;
    margin-bottom: 8px;
    letter-spacing: 2px;
    background: #43425d;
    color: #ffffff;
    border-radius: 7px;
    padding: 3px 7px;
    float: right;
}

/* noti */
.noti-dash {
    background-color: white;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 10px;
    margin-top: 10px;
    padding: 8px 12px 0px 12px;
    min-height: 180px;
}

/* battery */
.battery-dash {
    background-color: white;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 10px;
    margin-top: 10px;
    padding: 8px 12px 0px 12px;
    min-height: 180px;
}

.circle-val {
    height: 110px;
    width: 110px;
    padding: 0px 12px;
}

.circle-val .CircularProgressbar .CircularProgressbar-path {
    stroke: #18c18a !important;
}

.circle-val2 {
    height: 110px;
    width: 110px;
    padding: 0px 12px;
}

.circle-val2 .CircularProgressbar .CircularProgressbar-path {
    stroke: #a72a58e3 !important;
}


.bor-ln {
    border-bottom: 1px solid #edebf2;
    margin-bottom: 2px;
}

.bl-txt {
    margin-top: 10px;
    color: #6ba1d9;
    font-size: 18px;
    letter-spacing: 2px;
}


.gr-txt {
    margin-top: 10px;
    color: #2e704c;
    font-size: 18px;
    letter-spacing: 2px;
}

.re-txt {
    margin-top: 10px;
    color: #db6f6f;
    font-size: 18px;
    letter-spacing: 2px;
}

/* Battery Voltage */

.disabled{
    opacity: 0.5; 
    cursor: not-allowed; 
    pointer-events: none;

}
.battery-cont{
    background: #ffffff;
    border-radius: 10px;
    margin-top: 30px;
    padding: 40px;
    width: 100%;
     
}
.view-text{
    font-size: 18px !important;
    color: #3c3b54;
    letter-spacing: 0.5px;
    /* font-weight: 700; */
    border-radius: 10px;
    margin-bottom: 25px;
}


.battery-cont .main-sec{
    display: flex;
    flex-direction: row;
    /* max-width: 750px; */
    /* flex-wrap: wrap; */
}
.battery-card{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 203px;
    box-shadow: 0px 2px 6px #d4d2d9;
    border-radius: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 263px;
    
}
.title-sec{
    border-radius: 4px 4px 0px 0px;
    font-size: 17px;
    background-color: #7572C1;
    margin-bottom: 9px;
    text-align: center;
    letter-spacing: 1px;
    color: #ffffff;
    font-weight: 500;
}
.title-sec p{
    margin: 0px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
}
.disable-sec{
    border-radius: 4px 4px 0px 0px;
    font-size: 17px;
    background-color: #d6d6d6;
    margin-bottom: 9px;
    text-align: center;
    letter-spacing: 1px;
    color: #ffffff;
    font-weight: 500;
}
.disable-sec p{
    margin: 0px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
}
.meter-sec{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.meter-sec h5{
    color: #7572C1;
    margin-top: 10px;
}
.bottom-sec{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7572C1;
    margin-top: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0px 0px 4px 4px;
}
.bottom-sec p{
    margin: 0px !important;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
}
.battery-no-data{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 30px;
    padding: 20px;
    width: 370px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.battery-no-data-heading{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 20px;
    color: #3c3b54;
    letter-spacing: 0.5px;
    /* font-weight: 700; */
    padding: 10px 10px;
    margin-bottom: 10px;
    border-radius: 3px;
}
.activeSensorNodata{
    background: #ffffff;
    border-radius: 10px;
}
.activeSensorNodata h3{
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 25px;
    color: #3c3b54;
    letter-spacing: 0.5px;
}

