.temp {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 30px;
    padding: 15px;
}

@media (min-height:200px) and (max-height:767px) {
    .temp {

        background: #ffffff;
        border-radius: 10px;
        margin-top: 10px;
        padding: 15px;
    }
}

.card-sensor {
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 20px 12px;
    color: white;
}

.image-sensors {
    border-radius: 33px;

    width: 40px;
    height: 40px;
    margin-top: 16px;

}

.image-sensors img {
    padding: 6px 7px;
}

.warn-sen {

    background-color: white;
}

.active-sen {

    background-color: white;
}

.total-sen {

    background-color: white;
}

.warn-card {

    background: #f47f16;
}

.active-card {

    background: #00695b;
}

.total-card {

    background: #1a237d;
}

.sensor-name {
    font-size: 14px;
    margin-bottom: 13px;
    text-align: end;
    letter-spacing: 1px;

}

.sensor-degree {
    font-size: 18px;
    margin-top: 10px;
    text-align: end;
    margin-bottom: 8px;
    letter-spacing: 2px;

}

.donutchart-innertext {
    display: none !important;
}

.charts {
    background-color: white;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 10px;
    margin-top: 30px;
    height: 348px;
    max-height: 389px;


}

@media (min-height:200px) and (max-height:767px) {
    .charts {
        background-color: white;
        box-shadow: 0px 2px 6px #0000000a;
        border-radius: 10px;
        margin-top: 10px;
        height: 348px;
        max-height: 389px;
    }
}

.donut-chart {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hum-text {
    font-size: 16px;
    color: #3c3b54;
    letter-spacing: 0.5px;
    font-weight: 700;
    padding: 5px 18px;
    padding: 12px 18px;
}

.items-names {
    max-width: 250px;

    display: flex;
    flex-wrap: wrap;

    padding: 0;

    list-style: none;
}

.donut-chart .item-name {
    min-width: 70px;
    margin-top: 15px;
    height: 9px;
    padding-left: 25px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.humidity-text {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 10px;
    color: #3c3b54;
    letter-spacing: 0.5px;
    font-weight: 700;
}