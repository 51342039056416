/* daily Report */
.daily-report {
    margin-top: 50px;
    background: white;
    border-radius: 10px;
    overflow: auto;

}

.daily-report h4 {
    margin-top: 30px;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    letter-spacing: 1px;
}

.daily-report .report-table {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    color: #434174;
    letter-spacing: 0.5px;
    font-weight: 700;
    margin-bottom: 0px;
    border-radius: 3px;
    max-width: 95%;
}

.daily-report thead .dataTable {
    min-width: 100px;
    color: #3c3b54;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 700;
}

.daily-report tbody .databody {
    min-width: 120px;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0.5px;
    font-weight: 450;
    padding: 15px 0px;
}

.daily-report .table-responsive-xxl {
    overflow-x: auto;
}

/* dailysummary */

.daily-summary {
    margin-top: 50px;
    background: white;
    border-radius: 10px;
    overflow: auto;
}

.daily-summary .table .responsive {
    overflow-x: auto;
}

.daily-summary .summary-data h4 {
    color: #434174;
    font-size: 20px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 36px;
}

.daily-summary .summary-time {
    color: #ffffff;
    font-family: inherit;
    background-color: rgb(218 8 46 / 18%);


}

.daily-summary .summary-heading {
    color: #3c3b54;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 700;
    width: 109px;
    padding: 15px 0px;
    margin-bottom: 10px;
}

.daily-summary .summary-readings td {
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0.5px;
    font-weight: 450;
    width: 140px;
    padding: 10px 0px;
}

.summary-heading .line {
    border-right: 1px solid #e3e3e3ab;
}


.pdfButton.btn {
    background-color: green !important;
    cursor: pointer;

}

.pdfButton.btn:hover {
    background-color: red !important;
}

/* monthly report */

.Monthly-Report {
    margin-top: 50px;
    background: white;
    border-radius: 10px;
    overflow: auto;

}

.Monthly-Report .table-responsive-xxl {
    overflow: auto;
}

.Monthly h4 {
    color: #434174;
    font-size: 20px;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: 600;
    letter-spacing: 1px;
}

.Monthly-Report .head-1 {
    color: #3c3b54;
    font-size: 14px;
    font-weight: 700;
}

.Monthly-Report .head-2 td {
    font-size: 12px;
    text-align: -webkit-center;
    vertical-align: middle;
    letter-spacing: 0.5px;
    font-weight: 450;
    width: 140px;
    padding: 15px 0px;
}

.Monthly-Report.tbody,
.head-1,
tfoot,
.head-2,
thead,
tr {
    text-align: center;
}

.chart-Pop-Up {

    border: solid 1px #7572c1;
    border-radius: 10px;
    padding: 10px;

}

/* Tab */
.topReportTab {
    position: relative;

}

.reporttab {
    position: absolute;
    right: 0px;
    background: white;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px;
    top: -45px;
    left: 0px;
    box-shadow: 0px 2px 6px #0000000a;
    border-bottom: none;

}

@media (max-width:890px) {
    .reporttab {
        flex-wrap: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
        position: absolute;
        top: -45px;
        left: 59px;
        right: 51px;
        font-size: 14px;
        padding: 5px
    }
}

.reports .nav-item button {
    color: rgb(68, 49, 49);
    letter-spacing: 1px;
}

/* .reports .nav-item button:hover {
    color: rgb(255, 255, 255);

    background-color: rgb(137, 147, 160);
} */

.reports .nav-tabs .nav-link.active {
    background-color: #7572c1;
    border-radius: 4px !important;
    border-color: rgb(117 114 193);
    color: white;


}

/* ReportDrop */
.Report-drop-container {
    margin: 30px;

}

.Report-Drop {
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 10px;
    padding: 34px;
    background-color: white;
}

/* 
.drop-btn {
    height: 15px;
    position: relative;
    text-align: right;

} */


.drop-btn .drop-down-btn {
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    background-color: #7572c1;
    border: #7572c1;
    margin-bottom: 6px;
    padding: 6px 45px;
    font-size: 15px;
    letter-spacing: 1px;
    margin-top: 29px;
    height: 38px;
}

.drop-btn .drop-down-btn:hover {
    background-color: #8d8adf;
    color: #fff;
}

.Report-Drop Table.td {
    padding: 20px;

}

/* .Report-Drop svg {
    color: rgb(0, 0, 0);
    margin-right: 0px;
    margin-top: 10px;

} */

.Report-Drop .input-group-text {
    background-color: white;
}

.Report-Drop .form-control {
    border: none;
    height: 35px;
}

.Report-Drop .form-select {
    border: none;
    font-size: 14px;

}

.Report-Drop .form-label {
    font-size: 14px;
    letter-spacing: 0.5px;
}

.Report-Drop .form-select:focus {

    box-shadow: none;
}

.drop-btn svg {
    color: green;
    cursor: pointer;
    margin-left: 15px;
}

.drop-btn svg:hover {
    color: red
}

.reporttab li button {
    border: none !important;
}

.rep-container {
    max-width: 100%;
}

/* report example */
.report-tophead {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #3c3b54;
    letter-spacing: 0.5px;
    font-weight: 700;

}

.sub-tophead {
    font-size: 16px;
    margin-left: 50px;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    text-align: center;
}

.sub-subtop {
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
}

.report-exmple-location p {
    margin-bottom: 0px !important;
}

.example-report {
    margin-top: 20px !important;
}

.css-g1d714-ValueContainer {
    height: 38px;
    overflow: auto !important;
    /* padding: 0px !important;
    margin: 0px !important; */

    letter-spacing: 0px !important;
}

.css-12jo7m5 {
    padding-left: 1px !important;
    font-size: 13px !important;
}

.css-tlfecz-indicatorContainer {
    padding: 2px !important;
}

.css-1gtu0rj-indicatorContainer {
    padding: 2px !important;
}

.slect-change .input-group-text {
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border: 1px solid hsl(0, 0%, 80%);
    ;
    border-radius: 4px;

    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 0px 0px;

}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    box-shadow: 0 0 0 1px #2684ff !important;
    border: 1px solid #2684ff !important;
    border-radius: 4px !important;
}

.slect-change input {
    border-radius: 4px !important;
}