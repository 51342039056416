body {
    background-color: #edebf2;
    font-family: 'CalibriRegular'
}

.center-wrap {
    margin-left: 240px;
    transition-duration: 1s;
}

.no-center-wrap {
    margin-left: 60px;
    transition-duration: 1s;
}

.point {
    cursor: pointer;
}

a {
    text-decoration: none;
}

.space {
    margin-left: 15px;
    margin-bottom: 30px;
    margin-right: 15px;
}

@media(max-width:770px) {

    .center-wrap {

        margin-left: 0px;

    }

    .no-center-wrap {
        margin-left: 0px;
    }
}