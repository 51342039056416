body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "CalibriRegular";
  src: local("CalibriRegular"),
    url("./assets/fonts/CalibriRegular.ttf") format("truetype");
  font-weight: normal;
}