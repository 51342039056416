/* #header { */
    /* margin-left: 200px; */
/* } */

.top-header {
    background: #ffffff;
    box-shadow: 0px 2px 6px #0000000a;
    padding: 8px 12px;
}

.header-right {
    text-align: right;
}

.li {
    list-style-type: none;
}

.profile-img img {
    width: 30px;
    height: 30px;
    border-radius: 49px;
}

.logout-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 45px;
    transform: translateX(-50%);
    background-color: #ffffff;
    box-shadow: 0px 2px 6px #0000000a;
    z-index: 1;
    max-height: 400px;
    width: 253px;
    max-width: 81px;
    border-radius: 10px;
    border: 1px solid #c8c8df;
    left: 98%;
}

.notifyTexts {
    padding: 8px;
    cursor: pointer;
}

@media(min-width:770px) and (max-width:2560px) {

    .headerSideBar {
        display: none
    }

    .top-header {
        display: block !important;
        justify-content: end;
    }
}

@media screen and (max-width: 770px) {
    #header {
        margin-left: 0px;

    }
}

.headerSideBar {
    margin-left: 20px;

}

.headerContent {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 100px;
    position: relative;

}

.headerCanvas {
    top: 0;
    left: 0px;
    width: 10% !important;
    background-color: transparent;
    transition-duration: 0.02s
    
}

.headerCanvas .offcanvas-header {
    padding: 0px !important;

}

.headerCanvas .offcanvas-body {
    padding: 0px !important;

}

.headerCanvas .offcanvas-header .btn-close {
    display: none !important;

    /* position: absolute !important;
    top: 28px !important;
    right: 20px !important;
    color: white !important; */
}

@media screen and (min-width: 770px) {
    .mobileSidebar {
        display: none;

    }
}