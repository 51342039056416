.commonTable {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 30px;
    padding: 20px;
}

.groupTable {
    margin-top: 70px;
    padding: 1px;
}

.commonTable .heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    color: #3c3b54;
    letter-spacing: 0.5px;
    font-weight: 700;
    padding: 10px 10px;
    margin-bottom: 10px;
    border-radius: 3px;
}

.commonTable .table>thead {
    background: #43425d !important;
}

.commonTable .head th {
    text-align: center;
    vertical-align: middle;
    color: #ffffff !important;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 500;
    background: #43425d !important;
}

.commonTable .table-sm>:not(caption)>*>* {
    padding: 15px 0px !important;
}

.commonTable .images img {
    width: 25px;
    border-radius: 50%;
}

.commonTable .images {
    display: flex;
    justify-content: center;
}

.commonTable .image-data {
    vertical-align: middle;
}

.commonTable .row {
    overflow-x: auto;

}

.commonTable.tbody tr,
td {
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 1px;
    font-weight: 500;
    color: #4d4f5c;

}

.commonTable .red {
    padding: 6px 7px;
    border-radius: 5px;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 500;
    background: #c54848;
}

.commonTable .yellow {
    background-color: #307fdb;
    padding: 6px 7px;
    border-radius: 5px;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 500;
}

.commonTable .green {
    padding: 6px 7px;
    border-radius: 5px;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 500;
    background: #229f5b;
}

.commonTable .span-1 {
    text-align: center;
    vertical-align: middle;

}

.commonTable .data-1 {
    vertical-align: middle;
}

.commonTable .table-body {
    font-size: 14px;

}

.table-hover tbody tr:hover td {
    background-color: #ede8f9bf !important;

}

.table-body .pop-up .pop {
    padding: 6px 7px;
    border-radius: 5px !important;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 500;
    background: #307fdb;
}

.table-body .pop-up .resolvedBtn {
    padding: 6px 7px;
    border-radius: 5px !important;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 500;
    background: #6c757d;
}


.up {
    padding: 6px 7px;
    border-radius: 5px !important;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 500;
    background: #307fdb;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
    list-style: none;
    outline: none;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 25px;
}

.pagination>.active>a {
    background-color: #fff;
    border-color: rgba(255, 255, 255, 0.5);
    color: #fff;
}

.pagination>li>a {
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #7572c1;
    border-color: #7572c1;
    outline: none;
    color: #fff !important;
    border-radius: 4px;
}

.pagination>li>a,
.pagination>li>span {
    color: #000 !important;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: 6px;
}

.pagination li a {
    border-color: #7572c1;
    outline: none;
    border-radius: 4px;
    font-size: 12px;
    margin: 3px;
}

.userBtn {
    border-radius: 5px !important;
}

.gateWayAdd {
    border-radius: 5px !important;
    background: #307fdb !important;
    outline: none;

}

.addUser {
    border-radius: 5px !important;
    background: #307fdb !important;

}

.modalClose {
    border-radius: 5px !important;

}

.modalSave {
    border-radius: 5px !important;
    background: #307fdb !important;

}

.addScreen {
    border-radius: 5px !important;
    border: solid 1px #6c757d !important;
}

.statusGreen {
    background-color: #e6f8ee !important;
    color: #40cd7f;
    height: 25px;
    padding: 8px;
}
.statusOrange{
    background-color: #fdebeb !important;
    color: #ffa500;
    height: 25px;
    padding: 8px;
}

.statusRed {
    background-color: #fdebeb !important;
    color: #ef7a7b;
    height: 25px;
    padding: 8px;
}

/* userinfo */

.userInfo {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 30px;
    padding: 20px;
    max-width: 1000px;
    margin-inline: auto;
}

.userInfobtn {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
}

.groupHeader {
    background-color: #3c3b54;
    color: white;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.Threshold {
    width: 200px !important;
}

.standardAction {
    background: #ededed;
    border-radius: 5px;
    padding: 5px;
    line-height: 14px;
}

.standardAction .dl,
ol,
ul {
    color: #7572c1;
    list-style: none;
}

.notificationReportCard {

    border: solid 1px #7572c1;
    border-radius: 10px;
    padding: 20px;
    /* width: 468px; */

}

.pieChartMouse {
    cursor: none !important
}

/* .permission */

.checkBoxPermision {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem
}


.form-check-input:focus {
    border-color: #bfbfbf !important;
    outline: none;
    box-shadow:none
}