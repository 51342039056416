/* Sidebar Styles */

#sidebar {
  z-index: 1000;
  position: fixed;
  /* width: 200px; */
  height: 100%;
  background: #43425D;
  overflow-y: scroll;
}

#sidebar::-webkit-scrollbar {
  width: 0.0em !important;
}

#sidebar header {
  background-color: #43425D;


}

.logo-text {
  color: white;
  font-size: 23px;
  padding: 4px 12px 1px 12px;
  background: #3c3b54;
  font-weight: 600;
  margin-bottom: 0px;
}


#sidebar header a {
  color: white;

  text-decoration: none;

  margin-left: 20px;
}

#sidebar header a:hover {
  color: #fff;
}

.link {
  line-height: 52px;
  width: 100%;
  letter-spacing: 1px;
  color: #dfdbeb;
  opacity: 1;
  font-size: 14px;
  margin-top: 5px;
}

#sidebar .nav a:hover {

  color: #eceff1;
  background-color: #3C3B54;
  /* border-left: 4px solid #A3A0FB; */
}

#sidebar .active {
  color: #eceff1;
  background-color: #3C3B54;
  border-left: 4px solid #A3A0FB;
}

.side-left {
  margin-left: 20px;
  font-family: calibri;
}

.nav .img {
  width: 22px;
  height: 22px;
  margin-right: 24px;
}

.mobile-sidebar {
  visibility: hidden;
}

.desk-sidebar {
  visibility: visible;
}

.desk-sidebar.active {
  opacity: 1;
  visibility: hidden;
}

.mobile-sidebar.active {
  opacity: 1;
  visibility: visible;
}

.mobileTexts {
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.sub-menus {
  color: #eceff1;
}

.sensorDropActive {

  transition: 0.5s ease;
  transform: rotateZ(-180deg);
}

.sensorDropNon {

  transition: 0.5s ease;
  transform: rotateZ(0deg);
}

.sideButArrow {
  /* transition: 1s ease; */
  transform: rotateZ(0deg)
}

.noSideButArrow {
  /* transition: 1s ease; */
  transform: rotateZ(-180deg)
}

.basicDrop {
  margin-left: 55px;

}

.reportDrop {
  margin-left: 90px;
}
.manageDrop{
  margin-left: 40px;
}


@media (max-width:770px) {
  .sidebarHide {
    display: none;
  }
}

.sideBarButton {
  position: fixed !important;
  top: 60px;
  border-radius: 10px !important;
  /* left: 45px; */
  background: transparent;
  color: #8d8adf;
}

.sideBarButton:hover {

  background: transparent;
  color: #8d8adf;
}

.sideBarButton:active {
  background: transparent !important;
  color: #8d8adf !important;
  border: none !important;

}

.subone {
  width: 100px;
  border-radius: 5px;
  background-color: #3C3B54;
  left: 50px;
  opacity: 0;
  position: fixed;
  top: 111px !important;
  visibility: hidden;
  z-index: 1;
  display: flex;
  transition-duration: 0.5s;
  flex-direction: column;
}

.sub1:hover .subone {
  opacity: 1;
  top: 40px;
  /* adjust this as per top nav padding top & bottom comes */
  visibility: visible;
  transition-duration: 1s;
}

.sub1 {
  position: relative;

}

.subtwo {
  width: 130px;
  border-radius: 5px;
  background-color: #3C3B54;
  left: 50px;
  opacity: 0;
  position: fixed;
  top: 170px !important;
  visibility: hidden;
  z-index: 1;
  display: flex;
  transition-duration: 0.5s;
  flex-direction: column;
}

.sub2:hover .subtwo {
  opacity: 1;
  top: 40px;
  /* adjust this as per top nav padding top & bottom comes */
  visibility: visible;
  transition-duration: 1s;
}

.sub2 {
  position: relative;

}

.subthree {
  width: 155px;
  border-radius: 5px;
  background-color: #3C3B54;
  left: 50px;
  opacity: 0;
  position: fixed;
  top: 280px !important;
  visibility: hidden;
  z-index: 1;
  display: flex;
  transition-duration: 0.5s;
  flex-direction: column;
}

.sub3:hover .subthree {
  opacity: 1;
  top: 40px;
  /* adjust this as per top nav padding top & bottom comes */
  visibility: visible;
  transition-duration: 1s;
}

.sub3 {
  position: relative;

}

/* .arrow {
  position: fixed;

  top: 40px;
  left: 200px;

  animation: bounce 2s infinite;
  color: #8d8adf;
  background-size: contain;
}

.arrowSubMenu {
  position: fixed;
 
  top: 40px;
  left: 60px;
  
  animation: bounce 2s infinite;
  color: #8d8adf;
  background-size: contain;

}

@keyframes bounce {

  0%,
  20%,
  100% {
    transform: translateX(0);
  }

  10% {
    transform: translateX(-10px)
  }

  30%,
  70% {
    transform: translateX(10px);
  }

  50% {
    transform: translateX(0);
  }
} */