.analog-sec {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 30px;
    padding: 20px;

}

.sensor .view-text {
    font-size: 18px !important;
    color: #3c3b54;
    letter-spacing: 0.5px;
    font-weight: 700;
    border-radius: 10px;
    margin-top: 13px;
}


.sensor .view-img {
    float: right;
    cursor: pointer;
}

.sensor .view-img svg {
    margin-bottom: 10px;
    margin-right: 20px;
    padding: 7px;
    border-radius: 7px;
    width: 30px;
    height: 30px;
}

.SensorSvgs .change svg {
    background-color: rgba(182, 172, 172, 0.563);

}

.change-svg {

    border-radius: 7px;
    background: #dbdbdb;
    width: 30px !important;
    height: 30px !important;
    padding: 7px;
    margin-bottom: 10px;
}


.sensor .analog-card {
    margin-bottom: 10px;
    background-color: white;
    width: 200px;
    box-shadow: 0px 2px 6px #d4d2d9;
    border-radius: 2px;
    margin-left: 20px;
    margin-top: 20px;

}

.sensor .disabled{
    opacity: 0.5; 
    cursor: not-allowed; 
    pointer-events: none;
}

.sensor .title {
    border-radius: 4px 4px 0px 0px;
    font-size: 17px;
    margin-bottom: 9px;
    text-align: center;
    letter-spacing: 1px;
    color: #ffffff;
    font-weight: 500;

}

.sensor .title p {
    margin: 0px !important;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 16px;
    font-weight: 600;
}

.sensor .range {
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.5px;
    font-weight: 600;
}

.sensor .date {
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
}

.sensor .info {
    margin-top: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0px 0px 4px 4px;
}

.sensor .analog {
    margin-right: 15px;

}

.sensor .active {
    background: #ffffff;
    border-radius: 7px;
    height: 25px;
    width: 25px;
}

.sensors-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    justify-content: center;

}

.temperature-card .cards {
    border-radius: 15px;
    margin-bottom: 10px;
    padding: 8px 12px;
    background-color: white;
}

.temperature-card .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
}

.temperature-card .Img svg {
    margin-right: 6px;
    margin-left: 2px;

}

.temperature-card .Img h6 {
    font-size: 9px;
}

.temperature-card .footer {
    background-color: #e9f0e2;
    padding-top: 20px;

}

.temperature-card .FooterTexts {
    font-size: 14px;
    font-weight: 500;
}

.temperature-card .bg-body {
    background-color: #e9f0e2 !important;
    color: #26b429;
    border-radius: 19px;
}

.temperature-card .bg-body .danger {
    background-color: #8c1f1f !important;
    color: #00e1ff;
    border-radius: 19px;

}

.temperature-card .bg-body .normal {
    background-color: #d1ecf1 !important;
    color: #0c5460;
    border-radius: 19px;

}


.temperature-card .badge {
    width: 80px;
    background-color: #e9f0e2 !important;
    color: #26b429;

}

.temperature-card .danger {
    background-color: #f8d7da !important;
    color: #d54552;
}

.temperature-card .normal {
    background-color: #cee5f3 !important;
    color: #0d96e7;
}

.temperature-card .noData {
    width: 80px;
    background-color: #280202 !important;
    color: #474d79;
}

.SensorSvgs {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 10px;
}

.SensorSvgs svg:hover {
    background-color: rgb(212, 213, 201);
    color: #fff;

}

.digitalViewImg svg {
    margin-right: 20px;
}

.text-group text {
    font-size: 0px !important;
}

.meter p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
}

.temperature-card.bottomTxt {
    padding: 5px 0px 5px 5px;
}

.temperature-card {
    background: white;
    margin-bottom: 25px;
    padding: 10px 17px;
    color: white;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 10px;
}

.digital-image-sensors {
    width: 50px;
    height: 50px;
    margin-top: 33px
}

.digital-image-sensors svg {
    margin-top: 8px;
    margin-left: 8px;
}

.digital-degree {
    font-size: 18px;
    color: red;
    margin-top: 10px;
    text-align: end;
    margin-bottom: 8px;
    letter-spacing: 0px;
    font-weight: 600;
}

.digital-sens-text {
    font-size: 18px;
    margin-top: 10px;
    text-align: end;
    margin-bottom: 8px;
    letter-spacing: 0.5px;
    color: #3c3b54;
    font-weight: 600;
}

.digital-sens-range {
    font-size: 14px;
    margin-bottom: 13px;
    text-align: end;
    letter-spacing: 1px;
    color: #3c3b54;
    font-weight: 600;
}

.footer {
    background-color: #e9f0e2;
    padding-top: 20px;

}

.FooterTexts {
    font-size: 13px;
    font-weight: 500;
}

.bottomTxt {
    padding: 15px 0px 5px 5px;
    color: #3c3b54;
    font-weight: 600;
}

.red-digi {
    color: #d54552;
}

.green-digi {
    color: #26b429;
}

.blue-digi {
    color: #0d96e7;
}

.red-bg {
    background-color: #d54552;
}

.green-bg {
    background-color: #26b429;
}
.disable-bg {
    background-color:#d6d6d6;
}

.blue-bg {
    background-color: #0d96e7;
}

.no-bg {
    background-color: #636464;
}

.no-digi {
    color: #636464;
}

.echarts-for-echarts {
    height: 150px !important;
}

.nodata-text {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
    color: white;
    text-align: center;
}

.nodata {
    font-size: 18px;
    margin-bottom: 0px;
    color: #636464;
    text-align: center;
    height: 129px;
}

.nodata-digital {
    font-size: 18px;
    margin-bottom: 0px;
    color: #636464;
    text-align: center;
    height: 62px;
}

.meter .temp-val-digi {
    text-align: center;
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 600;
}

.svgCanvas.changeSelectedAttribute {
    height: 62px;
}

/* activeSensorDetails */

.sensorDetails {
    padding-top: 20px;
    color: white;
}

.activeSensor {
    background-color: #1a237d;
    border-radius: 4px;
    height: 125px;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 20px 12px;
    color: white;
}

.totalActive {
    font-size: 18px;
    text-align: end;
    margin-bottom: 8px;
    letter-spacing: 2px;
}

.totalHeading {
    font-size: 14px;
    margin-bottom: 13px;
    text-align: end;
    letter-spacing: 1px;
}

.activeSensorCard {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 30px;
    padding: 20px;
}

.activeSensorCard h3 {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 25px;
    color: #3c3b54;
    letter-spacing: 0.5px;
    /* font-weight: 700; */
}

/* groupDataView */
.groupDataView {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 30px;
    padding: 20px;


}