.login-Contaniner {
    background: white;
    height: 100vh;
}

.login-title {
    color: #6d66eb;
    margin-top: 20px;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 600;
}

.login-body {
    padding: 8px 30px
}

.login-submit {
    justify-content: center;
    display: flex;
}

.logins .form-label {
    color: #4d4f5c;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 14px !important;

}

.logins .form-control {
    font-size: 13px;
    line-height: 2;
    border: 1px solid #ced4da;
}

.login-footer {
    font-size: 15px;
    text-align: center;
    color: #4d4f5c;
    font-weight: 500;
    letter-spacing: 1px;
}


.btn-submit {
    transition: all .5s ease;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    background-color: #7572c1;
    border: #7572c1;
    margin-top: 18px;
    margin-bottom: 6px;
    padding: 6px 45px;
    font-size: 15px;
    letter-spacing: 1px;
}

.btn-submit:hover,
.btn-submit:focus {
    background-color: #8d8adf;
    color: #fff;
}


.error-text {
    font-size: 12px;
    color: red;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0.5px;
}

.password-icon {
    text-align: end;
    margin-top: -35px;
}

.login-card {
    margin-top: 100px;
    box-shadow: 0px 2px 6px #0000000a;
}


.logins input:focus,
.login-submit button:focus {
    border: 1px solid #8d8adf !important;
    box-shadow: none !important;
}

.logins .form-check-input:checked {
    background-color: #8d8adf !important;
    border-color: #8d8adf !important;
}

.logins .card,
.btn,
input {
    /* border-radius: 0 !important; */
    border: none;
}